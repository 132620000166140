import { AppText1, AppTitle3, AppTitle1 } from 'components/typography';
import React, { Component } from 'react';

export default class AppTitleAndSubTitle extends Component {
    render() {
        const { title, subTitle, otherClassNames = '', titleSize = 'normal' } = this.props;
        return (
            <div className={`app_title_and_sub_title_1 ${otherClassNames}`}>
                {titleSize === 'normal' ? (
                    <AppTitle3 text={title} />
                ) : (
                    <AppTitle1 text={title} otherClassNames="mb-2" color="primary" />
                )}
                <AppText1 text={subTitle} />
            </div>
        );
    }
}
