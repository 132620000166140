import { AppLink, AppButton, GetStartedStep } from 'components';
import { AppTitle1, AppTitle3, AppText1 } from 'components/typography';
import { APP_WIZARD_BASED_QUESTIONS_PAGE_URL } from 'common/configurations';
import { GetStartedStep1, GetStartedStep2, GetStartedStep3 } from 'assets/images';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';

export default class GetStartedPage extends AppPageComponent {
    getStartedStepsDataList = [
        {
            otherClassNames: 'step',
            image: GetStartedStep1,
            number: '1',
            title: 'Set your stewardship goals',
            description: 'The first few questions can help identify your vision as a steward',
        },
        {
            otherClassNames: 'step',
            image: GetStartedStep2,
            number: '2',
            title: 'Define your beneficiaries and priorities',
            description: `Identify who you will be serving and the impact you hope to
            make on their experience in the data economy`,
        },
        {
            otherClassNames: 'step',
            image: GetStartedStep3,
            number: '3',
            title: 'Explore and select choices',
            description: `Discover the range of choices and structural possibilities
            when it comes to decision-making, accountability and participation`,
        },
    ];

    render() {
        return (
            <div>
                <div className="page_section_1">
                    <div className="row app_row_padded_columns_md">
                        <div className="col-md-7">
                            <AppTitle1
                                text="Welcome to the Stewardship Navigator"
                                color="primary"
                            />
                            <AppText1
                                text="We’ve built this tool based on conversations we’ve had with
                                    organizations and initiatives across the globe who are committed
                                    to implementing or piloting responsible, people-centric systems
                                    of data governance. For any entity to be a ‘Steward’ of data we
                                    believe there are various structural decisions and choices
                                    that must be made."
                                isMultiLine
                            />
                            <AppText1
                                text="While there is no one size fits all solution, we hope that
                                    this tool surfaces a few key aspects that are useful to consider
                                    as a starting point on this journey"
                                isMultiLine
                            />
                            <AppLink to={APP_WIZARD_BASED_QUESTIONS_PAGE_URL}>
                                <AppButton text="Get Started" otherClassNames="mt-2" />
                            </AppLink>
                        </div>
                        <div className="col-md-5">
                            <div className="get_started_steps_page_wrapper">
                                <AppTitle3 text="How it works" color="primary" />
                                {this.getStartedStepsDataList.map((data) => (
                                    <GetStartedStep key="" {...data} />
                                ))}
                                    <AppButton
                                        text="Once you’ve answered these questions,"
                                        isTextOnly
                                        otherClassNames="get_started_link"
                                    />
                                    <AppButton
                                        text="View relevant stewardship models and case studies"
                                        isTextOnly
                                        otherClassNames="get_started_link"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
