import {
    APP_GET_STARTED_PAGE_URL,
    APP_LANDING_PAGE_URL,
    APP_OUR_DATA_MODELS_PAGE_URL,
    APP_WIZARD_BASED_QUESTIONS_PAGE_URL,
    APP_DATA_MODELS_RECOMMENDATION_PAGE_URL,
    APP_DATA_MODELS_DETAIL_PAGE_URL,
    APP_FULL_PAGE_LAYOUT_URLS,
    APP_CONTACT_PAGE,
} from 'common/configurations';

import {
    LandingPage,
    GetStartedPage,
    DevTestingPage,
    OurDataModelsPage,
    WizardBasedQuestionsPage,
    DataModelsRecommendationPage,
    IndividualDataModelPage,
    NotFoundPage,
    ContactPage,
} from 'pages';

import { Switch, Route, withRouter, matchPath } from 'react-router-dom';
import React, { Component } from 'react';

export class AppInnerPage extends Component {
    render() {
        const { location } = this.props;

        const isContainerized =
            matchPath(location.pathname, {
                path: APP_FULL_PAGE_LAYOUT_URLS,
                exact: true,
                strict: true,
            }) === null; // matched (!== null) | padding should not be added

        return (
            <div className={`${isContainerized ? 'app_inner_page_1 container' : ''}`}>
                <Switch>
                    <Route path="/dev-test" exact component={DevTestingPage} />
                    <Route
                        path={APP_DATA_MODELS_RECOMMENDATION_PAGE_URL}
                        exact
                        component={DataModelsRecommendationPage}
                    />
                    <Route
                        path={APP_OUR_DATA_MODELS_PAGE_URL}
                        exact
                        component={OurDataModelsPage}
                    />
                    <Route
                        path={APP_WIZARD_BASED_QUESTIONS_PAGE_URL}
                        exact
                        component={WizardBasedQuestionsPage}
                    />
                    <Route path={APP_GET_STARTED_PAGE_URL} exact component={GetStartedPage} />
                    <Route
                        path={APP_DATA_MODELS_DETAIL_PAGE_URL}
                        exact
                        component={IndividualDataModelPage}
                    />
                    <Route path={APP_LANDING_PAGE_URL} exact component={LandingPage} />
                    <Route path={APP_CONTACT_PAGE} exact component={ContactPage} />
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </div>
        );
    }
}

export default withRouter((props) => <AppInnerPage {...props} />);
