import React, { Component } from 'react';

export default class TagsHolder extends Component {
    render() {
        const { tags, otherClassNames = '', color = 'primary' } = this.props;
        return (
            <div className={`tags_holder ${otherClassNames}`}>
                {tags.map((element) => (
                    <span key={element} className={`badge rounded-pill bg-${color}`}>
                        {element}
                    </span>
                ))}
            </div>
        );
    }
}
