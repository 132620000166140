import React, { Component } from 'react';

/**
 * The just a wrapper component to convert all the non clickable components
 * to clickable component. Used for converting <p> tags to clickable.
 */
export default class AppJustClickableWrapper extends Component {
    render() {
        const { children, onClick, isDisabled = false } = this.props;

        return (
            <button
                disabled={isDisabled}
                onClick={onClick}
                type="button"
                className="app_just_clickable_wrapper"
            >
                {children}
            </button>
        );
    }
}
