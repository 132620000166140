import { AppTitle4 } from 'components/typography';
import { HtmlContentContainer } from 'components';
import React, { Component } from 'react';

const AccordionItem = ({ question, answer, index, accordionId }) => {
    const headingId = `heading-${index}`;
    const collapseId = `collapse-${index}`;
    return (
        <div className="accordion-item">
            <div className="accordion-header" id={headingId}>
                <button
                    className={`accordion-button ${index === 1 ? '' : 'collapsed'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded="true"
                >
                    <AppTitle4 text={question} otherClassNames="mb-0" />
                </button>
            </div>
            <div
                id={collapseId}
                className={`accordion-collapse collapse ${index === 1 ? 'show' : ''} `}
                aria-labelledby={headingId}
                data-bs-parent={`#${accordionId}`}
            >
                <div className="accordion-body">
                    <HtmlContentContainer content={answer} />
                </div>
            </div>
        </div>
    );
};

export default class FaqsList extends Component {
    render() {
        const { data, accordionId = 'accordionId', otherClassNames = '' } = this.props;
        return (
            <div className={`accordion faqs_list_accordion ${otherClassNames}`} id={accordionId}>
                {data.map((faq, index) => (
                    <AccordionItem
                        key={faq.key + faq.question}
                        question={faq.question}
                        answer={faq.answer}
                        index={index + 1}
                        accordionId={accordionId}
                    />
                ))}
            </div>
        );
    }
}
