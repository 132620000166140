// url config
export const APP_LANDING_PAGE_URL = '/';
export const APP_GET_STARTED_PAGE_URL = '/get-started';
export const APP_OUR_DATA_MODELS_PAGE_URL = '/our-data-models';
export const APP_WIZARD_BASED_QUESTIONS_PAGE_URL = '/interactive-guide';
export const APP_DATA_MODELS_RECOMMENDATION_PAGE_URL = '/model-recommendations';
export const APP_DATA_MODELS_DETAIL_PAGE_URL = '/data-models/:id';
export const APP_CONTACT_PAGE = '/contact';

// url vs layout config
export const APP_FULL_PAGE_LAYOUT_URLS = [APP_DATA_MODELS_DETAIL_PAGE_URL]; // not containerized

// app config
export const APP_NEED_HELP_MAIL_ADDRESS = 'contact@aapti.in';

// Temp content | TODO: remove later
export const DUMMY_CONTENT_1 = `
Data stewardship can unlock the societal value of data, empower
individuals and communities while safeguarding rights, helping
the world’s leading organizations succeed by solving their most
complex and sensitive security challenges.
`;

export const DUMMY_CONTENT_2 = `
We identify vulnerabilities in your environment and develop a
strategy to remediate & improve security posture. Our compliance
experts can prepare you for industry and regional directives like GDPR.
`;

export const DUMMY_CONTENT_3 = `
By answering a set of 5 questions, we take the first step of
goals being crafted right!
`;
