import { BrowserRouter as Router } from 'react-router-dom';
import { Header, Footer, AppInnerPage } from 'components';
import React, { Component } from 'react';

import 'assets/App.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';

export default class App extends Component {
    render() {
        return (
            <Router>
                {/* main page */}
                <div className="app_main_page_1">
                    {/* common header */}
                    <Header />

                    {/* the inner dynamic pages */}
                    <AppInnerPage />

                    {/* common footer */}
                    <Footer />
                </div>
            </Router>
        );
    }
}
