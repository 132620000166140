import { APP_GET_STARTED_PAGE_URL } from 'common/configurations';
import { AppButton, AppLink } from 'components';
import { LandingWavesBackground1 } from 'assets/images';
import React, { Component } from 'react';

import { AppText1 } from './typography';

export default class LandingPageCtaCard extends Component {
    render() {
        const { otherClassNames } = this.props;
        return (
            <div
                className={`${otherClassNames} app_landing_page_cta_card py-4 px-3`}
                style={{
                    backgroundImage: `url(${LandingWavesBackground1})`,
                }}
            >
                <AppText1
                    text="Translate stewardship to practice."
                    otherClassNames="trigger_text mb-1"
                />
                <AppText1
                    isMultiLine
                    otherClassNames="trigger_text"
                    text="A behind the scenes
                    guide to shaping responsible data sharing, governance and
                    participation in the data economy"
                />

                <AppLink to={APP_GET_STARTED_PAGE_URL}>
                    <AppButton color="light" text="Get Started" />
                </AppLink>
            </div>
        );
    }
}
