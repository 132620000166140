import {
    APP_GET_STARTED_PAGE_URL,
    APP_LANDING_PAGE_URL,
    APP_WIZARD_BASED_QUESTIONS_PAGE_URL,
    APP_FULL_PAGE_LAYOUT_URLS,
} from 'common/configurations';
import { AppLink } from 'components';
import { AppText1 } from 'components/typography';
import { connect } from 'react-redux';
import { Logo } from 'assets/images';
import { withRouter, matchPath } from 'react-router-dom';
import React, { Component } from 'react';

const ProgressBar = ({ progress }) => (
    <div className="progress app_progress_bar_1">
        <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuemin="0"
            aria-valuemax="100"
        />
    </div>
);

export class Header extends Component {
    render() {
        // props
        const { percentageCompleted, location } = this.props;

        // page level decision
        const includeProgressBar = [APP_WIZARD_BASED_QUESTIONS_PAGE_URL].includes(
            location.pathname,
        );

        const excludeHeaderBottomPadding =
            matchPath(location.pathname, {
                path: APP_FULL_PAGE_LAYOUT_URLS,
                exact: true,
                strict: true,
            }) !== null; // matched (!== null) | padding should not be added

        return (
            <div className={`app_header_1 ${excludeHeaderBottomPadding ? 'pb-0' : ''}`}>
                <nav className="app_navbar_1 navbar">
                    <div className="container">
                        <AppLink
                            to={APP_LANDING_PAGE_URL}
                            otherClassNames="navbar-brand app_top_bar_logo_1"
                        >
                            <img src={Logo} alt="" className="app_image_icon_1 w-120px" />
                        </AppLink>

                        <div className="w-100 d-flex justify-content-right">
                            <span className="app_navbar_right">
                                <AppLink to="/contact">
                                    <AppText1 text="Contact Us" color="primary" isBold />
                                </AppLink>
                                <AppLink to={APP_GET_STARTED_PAGE_URL}>
                                    <AppText1 text="Get Started" color="primary" isBold />
                                </AppLink>
                            </span>
                        </div>
                    </div>
                </nav>
                {includeProgressBar && <ProgressBar progress={percentageCompleted} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ percentageCompleted: state.percentageCompleted });

// state and router connected
export default connect(mapStateToProps)(withRouter((props) => <Header {...props} />));
