const APP_STATE = {
    test: null,
    percentageCompleted: 0,
    viewerToken: null,
};

export const appReducer = (state = APP_STATE, action) => {
    switch (action.type) {
        case 'TEST':
            return {
                ...state,
                test: action.payload || 'TEST',
            };
        case 'SET_PERCENTAGE_COMPLETED':
            return {
                ...state,
                percentageCompleted: action.payload,
            };
        case 'SET_VIEWER_TOKEN':
            return {
                ...state,
                viewerToken: action.payload,
            };
        default:
            return state;
    }
};
