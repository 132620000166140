import { AppButton, AppFontAwesomeIcon, TagsHolder, HtmlContentContainer } from 'components';
import { AppTitle1, AppText1 } from 'components/typography';
import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { triggerSimpleAjax } from 'appApi/helper';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';

class IndividualDataModelPage extends AppPageComponent {
    state = {
        serverData: {},
    };

    componentDidMount = () => {
        const { id } = this.props.match.params;

        triggerSimpleAjax(`api/individual-data-model-page-data/${id}/`).then((responseData) => {
            this.setState({
                serverData: responseData,
            });
        });
    };

    /**
     * The tab and tabContents are rendered separately. This is used to return the
     * centralized config for the tab and tab contents data.
     */
    getTabAndTabContentsConfig = (tabData, index) => {
        const isActive = index === 0;
        const tabId = `data_model_tab_${tabData.id}`;
        const tabContentId = `data_model_tabContent_${tabData.id}`;

        return {
            isActive,
            tabId,
            tabContentId,
        };
    };

    render() {
        const {
            identity,
            description,
            data_model_pdf_for_download = null,
            case_studies_pdf_for_download = null,
            key_characteristics = [],
            tab_contents = [],
        } = this.state.serverData;

        return (
            <div className="individual_data_models_page">
                {/* intro block for the data model */}
                <div className="landing_block">
                    <div className="container">
                        <div className="row app_row_padded_columns_md">
                            <div className="col-md-7">
                                <AppButton
                                    text="Back to Stewardship Models"
                                    isTextOnly
                                    otherClassNames="text-primary"
                                    icon={
                                        <AppFontAwesomeIcon
                                            icon={faChevronLeft}
                                            size="sm"
                                            color="primary"
                                            otherClassNames="me-1"
                                        />
                                    }
                                    onClick={this.props.history.goBack}
                                />
                                <AppTitle1 text={identity} otherClassNames="mb-2" />
                                <AppText1 otherClassNames="mb-0" text={description} isMultiLine />
                                <TagsHolder
                                    otherClassNames="mt-3"
                                    color="secondary"
                                    tags={key_characteristics}
                                />
                            </div>
                            <div className="col-md-5 d-flex align-items-center">
                                <div className="actions_holder">
                                    {data_model_pdf_for_download && (
                                        <AppButton
                                            onClick={(e) => {
                                                this.changeLocationOutsideWindow(
                                                    data_model_pdf_for_download,
                                                );
                                            }}
                                            text="Download Result as PDF"
                                            icon={
                                                <AppFontAwesomeIcon
                                                    icon={faDownload}
                                                    size="sm"
                                                    color="white"
                                                    otherClassNames="me-3"
                                                />
                                            }
                                        />
                                    )}
                                    {case_studies_pdf_for_download && (
                                        <AppButton
                                            onClick={(e) => {
                                                this.changeLocationOutsideWindow(
                                                    case_studies_pdf_for_download,
                                                );
                                            }}
                                            text="Download Case Study"
                                            icon={
                                                <AppFontAwesomeIcon
                                                    icon={faDownload}
                                                    size="sm"
                                                    color="primary"
                                                    otherClassNames="me-3"
                                                />
                                            }
                                            color="outline-primary"
                                            otherClassNames="mt-3"
                                            isBold
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tab & contents block */}
                <div className="content_navigation_tab">
                    <div className="container">
                        <ul className="nav nav-tabs" role="tablist">
                            {tab_contents.map((data, index) => {
                                const { isActive, tabId, tabContentId } =
                                    this.getTabAndTabContentsConfig(data, index);

                                return (
                                    <li key={`key-li-${data.title}`} className="nav-item">
                                        <button
                                            className={`nav-link ${isActive ? 'active' : ''}`}
                                            id={tabId}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#${tabContentId}`}
                                            type="button"
                                            role="tab"
                                        >
                                            <AppText1
                                                text={data.title}
                                                otherClassNames="mb-0"
                                                isBold
                                                color="dark"
                                            />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="tab_content_wrapper">
                    <div className="tab-content container">
                        {tab_contents.map((data, index) => {
                            const { isActive, tabContentId } = this.getTabAndTabContentsConfig(
                                data,
                                index,
                            );

                            return (
                                <div
                                    key={data.title}
                                    className={`tab-pane fade ${isActive ? 'show active' : ''}`}
                                    id={tabContentId}
                                >
                                    <HtmlContentContainer content={data.content} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default IndividualDataModelPage;
