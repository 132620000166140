import { AppTitle1, AppTitle4, AppText1 } from 'components/typography';
import React, { Component } from 'react';

export default class Steps extends Component {
    render() {
        const { image, number, title, description, otherClassNames = '' } = this.props;
        return (
            <div className={`app_get_started_steps_block ${otherClassNames}`}>
                <div className="app_steps_number_block">
                    <img src={image} className="app_image_icon_rounded_1 w-100px" alt="" />
                    <AppTitle1 color="primary" text={number} otherClassNames="app_steps_number" />
                </div>
                <div>
                    <AppTitle4 color="primary" text={title} otherClassNames="mb-1" />
                    <AppText1 text={description} otherClassNames="mb-0" />
                </div>
            </div>
        );
    }
}
