import React from 'react';
import { AppText1, AppTitle3 } from './typography';
import { AppButton } from './index';
import AppPageComponent from '../common/overrides/AppPageComponent';

export default class FeedBackCard extends AppPageComponent {
    clickHereHandler = (link) => {
        this.changeLocationOutsideWindow(link);
    };

    render() {
        const { title, description, link } = this.props;
        return (
            <div className="app_feedback_card card h-100">
                <div className="card-body">
                    <AppTitle3 otherClasses="mb-3" text={title} />
                    <AppText1 text={description} isMultiLine otherClassNames="mb-1" />
                    <AppButton
                        color="light"
                        text="Share feedback"
                        onClick={() => this.clickHereHandler(link)}
                    />
                </div>
            </div>
        );
    }
}
