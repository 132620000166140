import React from 'react';
import { triggerSimpleAjax } from 'appApi/helper';
import AppPageComponent from '../common/overrides/AppPageComponent';
import { AppText1, AppTitle3, AppTitle4, AppTitle1 } from '../components/typography';
import { InformationModal } from '../components';

export default class ContactPage extends AppPageComponent {
    state = {
        name: null,
        email: null,
        message: null,
        error: null,
        redirectCount: 5,
        isFormValid: false,
    };

    /**
     * A common input onChange handler
     * Stores the value of the input in state
     * Checks and updates the form validity
     * */
    handleInputChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
            },
            () => {
                this.checkFormValidity();
            },
        );
    };

    /**
     * Function to handle form submit
     * Used to send data to the server
     * */
    handleFormSubmit = (event, redirectCount) => {
        event.preventDefault();
        const { name, email, message } = this.state;
        const data = {
            name,
            email,
            message,
        };
        triggerSimpleAjax('/api/create-contact-us-lead/', 'POST', data)
            .then(() => this.triggerRedirect())
            .catch(() => {
                this.setState({
                    error: 'Something Went Wrong, Try again',
                });
            });
    };

    /**
     * Check and update form validity
     */
    checkFormValidity = () => {
        const { name, email, message } = this.state;
        this.setState({
            isFormValid: Boolean(name && email && message),
        });
    };

    /**
     * Function to redirect to Home Page after form submission
     * */
    triggerRedirect = () => {
        const countDownTimer = setInterval(() => {
            this.setState(
                (prevState) => ({
                    redirectCount: prevState.redirectCount - 1,
                }),
                () => {
                    const { redirectCount } = this.state;
                    if (redirectCount < 1) {
                        clearInterval(countDownTimer);
                        window.location.href = '/';
                    }
                },
            );
        }, 1000);
    };

    render() {
        const { name, email, message, error, redirectCount, isFormValid } = this.state;
        const description = `Thank You for submitting the form.
                You will be redirected to home page in ${redirectCount} seconds`;
        return (
            <div className="row contact_page">
                <div className="col-md-5">
                    <AppTitle1 text="Contact Us" otherClassNames="fw-bolder" />
                    <AppText1
                        text="Interested in how we built our tool or would just like to hear more?
                        We'd love to hear your thoughts and are keen to continue to iterate based
                        on suggestions from the community. Write to us!"
                        otherClassNames="mt-5"
                    />
                </div>
                <div className="col-md-6 contact_form">
                    {error ? <AppText1 text={error} otherClassNames="text-danger fw-bold" /> : null}
                    <AppTitle3 text="Enter Details Below" />
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">
                                <AppTitle4 text="Name *" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={name}
                                name="name"
                                id="name"
                                onChange={this.handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">
                                <AppTitle4 text="Email *" />
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                name="email"
                                id="email"
                                onChange={this.handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">
                                <AppTitle4 text="Message *" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={message}
                                name="message"
                                id="message"
                                onChange={this.handleInputChange}
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            data-bs-toggle={isFormValid && 'modal'}
                            data-bs-target="#contact_success_modal"
                            className="btn btn-primary"
                        >
                            Send Request
                        </button>
                        <InformationModal
                            title="Thank You"
                            description={description}
                            modalId="contact_success_modal"
                            buttonHide
                        />
                    </form>
                </div>
            </div>
        );
    }
}
