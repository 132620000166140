import { Popover, OverlayTrigger } from 'react-bootstrap';
import React, { Component } from 'react';

import { AppText1 } from './typography';

/**
 * The main handler for the `AppToolTip`. The `AppToolTip` is just a wrapper.
 * While this component is the actual handler for the tooltip.
 */
const AppToolTipHandler = ({ heading, description }) => (
    <Popover className="app_tooltip" id="popover-basic">
        <Popover.Body>
            {heading && <AppText1 text={heading} otherClassNames="mb-3 text-center" isBold />}

            <AppText1 text={description} otherClassNames="m-0 text-center" />
        </Popover.Body>
    </Popover>
);

export default class AppToolTip extends Component {
    render() {
        const { children, content, show = true } = this.props;

        if (show) {
            return (
                <OverlayTrigger
                    trigger="hover"
                    placement="top"
                    overlay={AppToolTipHandler(content)}
                >
                    {children}
                </OverlayTrigger>
            );
        }

        return children;
    }
}
