import React from 'react';

export const AppTitle3 = ({
    text,
    otherClassNames = '',
    color = 'black',
    isBold = true,
    isMultiLine = false,
}) => (
    <h5
        className={`
            text-${color}
            ${otherClassNames}
            app_title_3
            ${isBold ? 'app_font_weight_bold_1' : ''}
            ${isMultiLine ? 'app_line_spacing_2' : ''}
        `}
    >
        {text}
    </h5>
);

export default AppTitle3;
