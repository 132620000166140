import { APP_NEED_HELP_MAIL_ADDRESS } from 'common/configurations';
import { AppFontAwesomeIcon, AppLink } from 'components';
import { AppText1, AppTitle4 } from 'components/typography';
import { faTwitter, faYoutube, faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons';
import { Logo } from 'assets/images';
import React, { Component } from 'react';

export default class Footer extends Component {
    socialLinksList = [
        {
            link: 'https://twitter.com/aaptiinstitute?lang=en',
            icon: faTwitter,
        },
        {
            link: 'https://www.youtube.com/channel/UC3Suw5GYGa_7Q6Ltv4hnl3w',
            icon: faYoutube,
        },
        {
            link: 'https://in.linkedin.com/company/aapti-institute',
            icon: faLinkedin,
        },
        {
            link: 'https://aapti.medium.com/aapti-institute-data-economy-lab-a3a161f50354',
            icon: faMedium,
        },
    ];

    footerDynamicLinksList = [
        {
            title: 'Navigation',
            links: [
                {
                    href: 'https://thedataeconomylab.com/',
                    title: 'About Us',
                },
                {
                    link: '#',
                    title: 'Resources',
                },
                {
                    link: '#',
                    title: 'Contact Us',
                },
            ],
        },
    ];

    render() {
        return (
            <footer className="container app_footer_1">
                <div className="row mb-2 app_row_padded_columns_md">
                    <div className="col-md-5 app_footer_image_col">
                        <img src={Logo} alt="" className="app_image_icon_1 w-120px mb-3" />
                    </div>
                    {this.footerDynamicLinksList.map((singleLink) => (
                        <div key={singleLink.title} className="col-md-2 col-6">
                            <AppTitle4 text={singleLink.title} />

                            {singleLink.links.map((subLink) => (
                                <AppLink key={subLink.link} to={subLink.link} href={subLink.href}>
                                    <AppText1 text={subLink.title} />
                                </AppLink>
                            ))}
                        </div>
                    ))}

                    <div className="col-md-5">
                        <AppTitle4 text="Contact Us" />
                        <AppText1
                            isMultiLine
                            text={`If you have comments, suggestions or would like
                            to learn more reach out to us at `}
                            SuffixComponent={
                                <AppLink href={`mailto:${APP_NEED_HELP_MAIL_ADDRESS}`}>
                                    <AppText1
                                        otherClassNames="d-inline"
                                        isMultiLine
                                        color="primary"
                                        text={APP_NEED_HELP_MAIL_ADDRESS}
                                    />
                                </AppLink>
                            }
                        />
                    </div>
                </div>

                <div className="app_footer_bottom">
                    <AppLink to="#">
                        <AppText1 text="Privacy Policy" />
                    </AppLink>

                    <div className="social_icons">
                        {this.socialLinksList.map((value) => (
                            <AppLink
                                otherClassNames="d-inline ms-3"
                                key={value.link}
                                href={value.link}
                            >
                                <AppFontAwesomeIcon icon={value.icon} />
                            </AppLink>
                        ))}
                    </div>
                </div>
            </footer>
        );
    }
}
