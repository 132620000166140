import { APP_LANDING_PAGE_URL } from 'common/configurations';
import { AppButton, AppLink } from 'components';
import { AppTitle1, AppText1 } from 'components/typography';
import { LandingPageBrain } from 'assets/images';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';

export default class NotFoundPage extends AppPageComponent {
    render() {
        return (
            <div>
                <div className="page_section_1">
                    <div className="landing_hero_section row app_row_padded_columns_lg">
                        <div className="col-lg-6 left_section">
                            <AppTitle1 text="404 Page Not Found" color="primary" />
                            <AppText1
                                text="The page you were looking for doesn't exist. You may have
                                mistyped the address or the page may have been moved. Please use
                                the given link below to navigate back to our home page."
                                isMultiLine
                                otherClassNames="mb-0"
                            />
                            <div className="mb-2 actions_section">
                                <AppLink to={APP_LANDING_PAGE_URL}>
                                    <AppButton
                                        otherClassNames="mt-2"
                                        text="Go Back To Home Page"
                                        color="outline-primary"
                                        isTextOnly
                                    />
                                </AppLink>
                            </div>
                        </div>
                        <div className="col-lg-6 right_section">
                            <img
                                src={LandingPageBrain}
                                className="img-fluid app_image_icon_1"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
