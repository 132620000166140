import React from 'react';

export const AppText1 = ({
    text,
    otherClassNames = '',
    color = 'black',
    isMultiLine = false,
    isBold = false,
    SuffixComponent = null, // wants to display a component at the end
}) => (
    <p
        className={`text-${color} ${otherClassNames} app_text_1 ${
            isMultiLine ? 'app_line_spacing_1' : ''
        } ${isBold ? 'app_font_weight_bold_1' : ''}`}
    >
        {text}
        {SuffixComponent}
    </p>
);

export default AppText1;
