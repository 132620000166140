import React, { Component } from 'react';
import { AppText1 } from './typography';

export default class WizardProgressTracker extends Component {
    render() {
        const { otherClassNames = '', totalAnswered, totalQuestions } = this.props;

        // get and pre-process
        // let { progress } = this.props;
        // progress = parseInt(progress, 10);
        // progress += '%';

        return (
            <div className={`app_progress_tracker ${otherClassNames}`}>
                <AppText1
                    text={`You have completed out of ${totalQuestions}`}
                    otherClassNames="app_progress_tracker_text"
                    isBold
                />
                <div className="app_circle_1 p-3 bg-primary">
                    <AppText1 text={totalAnswered} otherClassNames="mb-0" color="white" isBold />
                </div>
            </div>
        );
    }
}
