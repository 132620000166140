import { Provider } from 'react-redux';
import appStore from 'appRedux/store';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const injectGA = () => {
    if (typeof window == 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'G-42P360HQ9C');
};

ReactDOM.render(
    <React.StrictMode>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-42P360HQ9C"
        />
        <script>{injectGA()}</script>
        <Provider store={appStore}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
