import React, { Component } from 'react';
import { AppButton, TagsHolder } from 'components';
import { AppTitle3, AppText1 } from './typography';

export default class SingleDataModelCard extends Component {
    render() {
        const {
            title,
            description,
            keyCharacteristicsList,
            caseStudyImagesList = [],
            viewInDetailHandler = () => {},
            id,
        } = this.props;
        return (
            <div className="app_single_data_model_card card h-100">
                <div className="card-body">
                    {/* title and description */}
                    <AppTitle3 otherClasses="mb-3" text={title} />
                    <AppText1 text={description} isMultiLine otherClassNames="mb-1" />

                    {/* card characteristics list */}
                    <TagsHolder tags={keyCharacteristicsList} otherClassNames="mt-3 mb-3" />

                    {/* case study images */}
                    {caseStudyImagesList.length > 0 && (
                        <div className="card_case_studies">
                            {caseStudyImagesList.map((url) => (
                                <img
                                    key=""
                                    src={url}
                                    className={`model_card_logo ${id === 3 && 'w-100'}`}
                                    alt=""
                                />
                            ))}
                        </div>
                    )}

                    <hr />

                    {/* actions */}
                    <div className="model_card_footer">
                        <AppButton
                            color="outline-primary"
                            isTextOnly
                            text="View In Detail"
                            onClick={viewInDetailHandler}
                            otherClassNames="m-0 p-0"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
