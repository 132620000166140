import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InformationModal, AppButton, AppToolTip } from 'components';
import React, { Component } from 'react';

import { AppFontAwesomeIcon } from './index';
import { AppTitle4, AppText1 } from './typography';

export default class WizardOptionCard extends Component {
    render() {
        const {
            id,
            title,
            description,
            icon,
            info = null,
            infoTitle = null,
            isDisabled = false,
            isSelected = false,
            onClick = () => {},
        } = this.props;

        const INFORMATION_MODAL_ID = `info-modal-${id}`;

        const disabledToolTipUserNotification = {
            heading: 'Why you can’t select this option',
            description: `
                Based on your previous choices and our dataset, this guide has identified
                options that are available. Feel free to go back and select different options.
            `,
        };

        return (
            <AppToolTip content={disabledToolTipUserNotification} show={isDisabled}>
                <div
                    className={`
                        app_wizard_option_card card
                        ${isSelected ? 'selected' : ''}
                        ${isDisabled ? 'disabled' : 'not_disabled'}
                    `}
                    onClick={onClick}
                    aria-hidden="true"
                >
                    <div className="card-body">
                        <div className="d-flex">
                            <span className="options_card_radio">
                                <input type="radio" checked={isSelected} />
                                <span className="app_radio_control" />
                            </span>

                            <div className="mx-3">
                                <div className="d-flex justify-content-between mb-2">
                                    <AppTitle4 text={title} otherClassNames="mb-2" />
                                    {icon &&
                                        <img src={icon} className="options_question_icon" alt='' />
                                    }
                                </div>
                                <AppText1 text={description} otherClassNames="mb-0" />
                            </div>
                        </div>

                        {info ? (
                            <div className="options_info_icon">
                                <AppButton
                                    color="transparent"
                                    otherClassNames="p-3"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${INFORMATION_MODAL_ID}`}
                                    type="button"
                                    icon={<AppFontAwesomeIcon icon={faInfoCircle} size="md" />}
                                />
                                <InformationModal
                                    title={infoTitle}
                                    description={info}
                                    modalId={INFORMATION_MODAL_ID}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </AppToolTip>
        );
    }
}
