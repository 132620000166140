import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

export default class AppFontAwesomeIcon extends Component {
    render() {
        const { icon, size = 'lg', color = 'black', otherClassNames = '' } = this.props;
        return (
            <FontAwesomeIcon
                className={`${otherClassNames} app_font_awesome_icon_1`}
                icon={icon}
                size={size}
                color={color}
            />
        );
    }
}
