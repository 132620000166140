import { APP_GET_STARTED_PAGE_URL, APP_OUR_DATA_MODELS_PAGE_URL } from 'common/configurations';

import {
    AppButton,
    AppLink,
    FaqsList,
    // LandingPageCtaCard,
    CaseStudyCard,
    DecisionMakingCard,
    AppFontAwesomeIcon,
} from 'components';
import { AppTitle1, AppText1, AppTitle4, AppTitle3 } from 'components/typography';
import { LandingPageBrain, DecisionMakingAirTable, AppWizardScreenShot } from 'assets/images';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default class LandingPage extends AppPageComponent {
    state = {
        serverData: {},
        showScrollTop: false,
    };

    /**
     * Check the Y offset of the page
     * Update the state
     */
    checkScrollTop = () => {
        const { showScrollTop } = this.state;
        if (!showScrollTop && window.pageYOffset > 400) {
            this.setState({
                showScrollTop: true,
            });
        } else if (showScrollTop && window.pageYOffset <= 400) {
            this.setState({
                showScrollTop: false,
            });
        }
    };

    /**
     * Handle Click on scroll to top
     * */
    handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    API_ENDPOINT_FOR_INITIAL_PAGE_DATA = 'api/landing-page-data/';

    DECISION_MAKING_CARD_CONTENT = [
        {
            heading: 'What does it take to transform into a Steward?',
            subHeading: 'Delve into stewardship',
            description:
                'Stewardship holds great promise for improving systems ' +
                'for data collection, storage, exchange, and negotiation - explore what ' +
                'this could mean for your organization',
            buttonContent: [
                {
                    text: 'Get Started',
                    link: '/get-started',
                },
            ],
            buttonLink: '',
            image: AppWizardScreenShot,
        },
        {
            subHeading: 'The Data Economy Lab’s Approach',
            heading: 'Our methodology & journey behind creating the tool',
            description: 'Read about how we built this interactive guide and view analysis',
            isReversed: true,
            buttonContent: [
                {
                    text: 'View database',
                    externalLink: 'https://airtable.com/shrH2IvivQ0ughB94',
                },
                {
                    text: 'Read methodology',
                    externalLink:
                        'https://thedataeconomylab.com/2021/09/28' +
                        '/building-the-stewardship-navigator-our-approach-methodology/',
                },
            ],
            image: DecisionMakingAirTable,
        },
    ];

    render() {
        const { showScrollTop } = this.state;
        const { faqs_data = [], case_studies_data = [] } = this.state.serverData;

        // TODO: Temporary fix
        if (case_studies_data.length !== 0) {
            case_studies_data[0].buttonText = 'Explore Models';
            case_studies_data[0].buttonLink = 'https://thedataeconomylab.com/tracking-stewardship/';
            case_studies_data[1].buttonText = 'Watch Interviews';
            case_studies_data[1].buttonLink = 'https://thedataeconomylab.com/videos/';
        }

        // Listener for topScroll
        window.addEventListener('scroll', this.checkScrollTop);
        return (
            <div>
                <div className="page_section_1">
                    <div className="landing_hero_section row app_row_padded_columns_lg">
                        <div className="col-lg-6 left_section">
                            <AppTitle4 text="Tools & Guides" />
                            <AppTitle1 text="Stewardship Navigator" color="primary" />
                            <AppText1
                                text="An interactive tool that guides users through various
                                foundational decisions and structural considerations for
                                building a steward"
                                isMultiLine
                            />
                            <div className="mb-2 actions_section">
                                <AppLink to={APP_GET_STARTED_PAGE_URL}>
                                    <AppButton
                                        text="Start Your Stewardship Journey"
                                        otherClassNames="me-4_5 mt-2"
                                    />
                                </AppLink>
                                <AppLink to={APP_OUR_DATA_MODELS_PAGE_URL}>
                                    <AppButton
                                        otherClassNames="mt-2"
                                        text="Explore Data Stewardship Models"
                                    />
                                </AppLink>
                            </div>
                        </div>
                        <div className="col-lg-6 right_section">
                            <img
                                src={LandingPageBrain}
                                className="img-fluid app_image_icon_1"
                                alt=""
                            />
                        </div>
                    </div>
                    {this.DECISION_MAKING_CARD_CONTENT.map((content) => (
                        <DecisionMakingCard
                            key={content.heading}
                            heading={content.heading}
                            subHeading={content.subHeading}
                            description={content.description}
                            buttonContent={content.buttonContent}
                            isReversed={content.isReversed}
                            image={content.image}
                            otherClassNames="mt-4"
                        />
                    ))}

                    <FaqsList
                        otherClassNames="no_app_row_padded_columns_fix_1"
                        data={faqs_data.map((faq) => ({
                            key: faq.id,
                            question: faq.identity,
                            answer: faq.description,
                        }))}
                        accordionId="accordionFaq"
                    />
                </div>
                <div className="page_section_1">
                    <AppTitle3 text="Resources" />
                    <div className="row app_just_row_padded_columns">
                        {case_studies_data.map((data) => (
                            <div className="col-md-6 col-lg-6" key={data.id + data.identity}>
                                <CaseStudyCard
                                    title={data.identity}
                                    description={data.description}
                                    image={data.thumbnail_image}
                                    buttonText={data.buttonText}
                                    onClickLink={data.buttonLink}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="page_section_1">
                    {/* Commented out as per client request */}
                    {/* <LandingPageCtaCard /> */}
                </div>

                {/* Scroll To Top Button */}
                <AppButton
                    color="light"
                    icon={<AppFontAwesomeIcon icon={faChevronUp} size="sm" />}
                    otherClassNames={`app_scroll_top_button ${showScrollTop ? 'd-flex' : 'd-none'}`}
                    onClick={this.handleScrollTop}
                />
            </div>
        );
    }
}
