import React from 'react';

export const AppTitle4 = ({ text, otherClassNames = '', color = 'black', isBold = true }) => (
    <h5
        className={`
            text-${color}
            ${otherClassNames}
            app_title_4
            ${isBold ? 'app_font_weight_bold_1' : ''}
        `}
    >
        {text}
    </h5>
);

export default AppTitle4;
