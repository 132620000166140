import { AppLink } from 'components';
import { AppTitle3, AppText1 } from 'components/typography';
import { CaseStudyPlaceholder } from 'assets/images';
import React, { Component } from 'react';

export default class CaseStudyCard extends Component {
    render() {
        const {
            image = CaseStudyPlaceholder,
            title,
            description,
            onClickLink = '#',
            buttonText,
        } = this.props;

        return (
            <div className="app_case_study_card_container">
                <div className="app_case_study_card card">
                    <div className="card-body">
                        <img alt="" src={image} className="card_image app_image_icon_1" />

                        <AppTitle3 text={title} />
                        <AppText1 text={description} isMultiLine />
                        <AppLink href={onClickLink}>
                            <AppText1
                                text={buttonText}
                                color="info"
                                isBold
                                otherClassNames="mb-0"
                            />
                        </AppLink>
                    </div>
                </div>
            </div>
        );
    }
}
