import { Link } from 'react-router-dom';
import React, { Component } from 'react';

export default class AppLink extends Component {
    render() {
        const { to, href, children, otherClassNames = '' } = this.props;
        return href ? (
            <a
                href={href}
                className={`${otherClassNames} app_link`}
                target="_blank"
                rel="noreferrer"
            >
                {children}
            </a>
        ) : (
            <Link to={to} className={`${otherClassNames} app_link`}>
                {children}
            </Link>
        );
    }
}
