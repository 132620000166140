import { WizardQuestionsTracker } from 'components';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';

export default class DevTestingPage extends AppPageComponent {
    questionGroupsAndQuestionsData = [
        {
            title: 'Getting Started',
            isRequired: true,
            questionsList: [
                {
                    id: 1,
                    title: 'Sector',
                    hint: 'What is your core purpose as a steward',
                },
                {
                    id: 2,
                    title: 'Region',
                    hint: 'What is your core purpose as a steward',
                },
                {
                    id: 3,
                    title: 'Type of Model',
                    hint: 'What is your core purpose as a steward',
                },
            ],
            answersDict: { 1: 'Health, Infrastructure & Services', 2: 'North America' },
        },
    ];

    render() {
        return (
            <div className="col-md-4">
                <WizardQuestionsTracker data={this.questionGroupsAndQuestionsData} />
            </div>
        );
    }
}
