import { APP_DATA_MODELS_RECOMMENDATION_PAGE_URL } from 'common/configurations';
import { AppTitle3, AppText2, AppText1 } from 'components/typography';
import { connect } from 'react-redux';
import { faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { triggerSimpleAjax } from 'appApi/helper';

import {
    WizardProgressTracker,
    WizardOptionCard,
    AppButton,
    AppFontAwesomeIcon,
    AppJustClickableWrapper,
    AppLoadableWrapper,
    WizardQuestionsTracker,
    InformationModal,
    AppToolTip,
} from 'components';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';

/**
 * When the user moves to the next or pervious question, these variables
 * have to be reset in the state, just a common constant.
 */
const COMMON_NEXT_OR_PREVIOUS_QUESTION_STATE_VARIABLES = {
    errorInformation: null,
    currentSelectedOptions: [],
};

export class WizardBasedQuestionsPage extends AppPageComponent {
    state = {
        isLoading: true, // initial loader | reset after componentDidMount

        serverData: {}, // server related data
        currentQuestionIndex: 0, // index of question from `allAvailableQuestionsStore`
        allAvailableQuestionsStore: [], // list of question objects
        allAvailableQuestionGroupsStore: [], // list of question group objects
        allQuestionGroupIdsAndQuestionIdsStore: {}, // { <questionGroupId>: [...questionIds] }

        globalQuestionAndOptionsSelected: {}, // for global level { <questionId>: [optionIds] }
        currentSelectedOptions: [], // actual store
        answersDict: {}, // just for display

        // user notify error & info
        errorInformation: null,
        allOptionsSaved: false,
    };

    /**
     * Returns all the selected option ids from the globalQuestionAndOptionsSelected.
     * Used to send data to the server to receive data accordingly.
     */
    getAllSelectedOptionIds = () => {
        const { globalQuestionAndOptionsSelected } = this.state;
        let allSelectedOptionIds = [];

        for (let [, optionIdsList] of Object.entries(globalQuestionAndOptionsSelected)) {
            optionIdsList = optionIdsList.map((id) => parseInt(id, 10));
            allSelectedOptionIds = [...allSelectedOptionIds, ...optionIdsList];
        }

        return allSelectedOptionIds;
    };

    /**
     * Get all necessary data from the server, like data for the left component, right component.
     */
    componentDidMount() {
        // Set percentage to zero on load
        const { setPercentageCompleted } = this.props;
        setPercentageCompleted(0);

        triggerSimpleAjax('api/interactive-guide-page-initial-data/').then((responseData) => {
            const { questions_groups_and_questions = [] } = responseData;
            // for store
            const allAvailableQuestionsStore = [];
            const allAvailableQuestionGroupsStore = [];
            const allQuestionGroupIdsAndQuestionIdsStore = {};

            questions_groups_and_questions.map((questionGroupData) => {
                allQuestionGroupIdsAndQuestionIdsStore[questionGroupData.id] = [];
                allAvailableQuestionGroupsStore.push(questionGroupData);

                questionGroupData.questions.map((questionData) => {
                    allAvailableQuestionsStore.push(questionData);
                    allQuestionGroupIdsAndQuestionIdsStore[questionGroupData.id].push(
                        questionData.id,
                    );
                });
            });

            this.setState(
                {
                    ...this.state,
                    serverData: responseData,
                    allAvailableQuestionsStore,
                    allAvailableQuestionGroupsStore,
                    allQuestionGroupIdsAndQuestionIdsStore,
                    isLoading: false,
                },
                () => {
                    this.fetchAndSetCurrentQuestionDetails();
                },
            );
        });
    }

    /**
     * Very similar to `getCurrentQuestionDetails` but returns the question group
     * for the active question. This is used for validations and stuff.
     */
    getCurrentQuestionGroupDetails = () => {
        const questionData = this.getCurrentQuestionDetails();
        const { allQuestionGroupIdsAndQuestionIdsStore, allAvailableQuestionGroupsStore } =
            this.state;

        for (const [questionGroupId, questionIds] of Object.entries(
            allQuestionGroupIdsAndQuestionIdsStore,
        )) {
            if (questionIds.includes(questionData.id)) {
                for (let index = 0; index < allAvailableQuestionGroupsStore.length; index += 1) {
                    const questionGroupData = allAvailableQuestionGroupsStore[index];

                    if (questionGroupData.id === parseInt(questionGroupId, 10)) {
                        return questionGroupData;
                    }
                }
            }
        }

        return {};
    };

    /**
     * Gets the current question based on the currentQuestionIndex and allAvailableQuestionsStore.
     * This is just a centralized function to get the question.
     */
    getCurrentQuestionDetails = () => {
        const { currentQuestionIndex, allAvailableQuestionsStore } = this.state;
        return allAvailableQuestionsStore[currentQuestionIndex];
    };

    /**
     * Given the `currentQuestionId` in the state, this gets the question details
     * from the server and sets it on the screen along with the options.
     */
    fetchAndSetCurrentQuestionDetails = () => {
        const currentQuestion = this.getCurrentQuestionDetails();
        const { globalQuestionAndOptionsSelected } = this.state;
        this.setState({
            isLoading: true,
        });
        triggerSimpleAjax(`api/question-with-options/${currentQuestion.id}/`, 'post', {
            other_linkages: this.getAllSelectedOptionIds(),
        }).then((responseData) => {
            const { serverData = {} } = this.state;

            this.setState({
                ...this.state,
                currentSelectedOptions: globalQuestionAndOptionsSelected[currentQuestion.id] || [],
                serverData: {
                    ...serverData,
                    currentQuestionData: responseData,
                },
                allOptionsSaved: false,
                isLoading: false,
            });
        });
    };

    /**
     * Given the Id of a question, this returns the index of the question
     * with the given Id in the question store.
     */
    getIndexOfQuestionFromStoreWhereQuestionId = (questionId) => {
        const { allAvailableQuestionsStore } = this.state;

        for (let index = 0; index < allAvailableQuestionsStore.length; index += 1) {
            const questionData = allAvailableQuestionsStore[index];
            if (questionData.id === questionId) {
                return index;
            }
        }

        return null;
    };

    /**
     * Handles the fact that the user has selected a question section.
     * Like reload the questions answers or load fresh page etc.
     */
    onQuestionSectionClicked = (questionId, ...rest) => {
        const { currentQuestionIndex } = this.state;

        const destinationQuestionIndex =
            this.getIndexOfQuestionFromStoreWhereQuestionId(questionId);

        if (
            destinationQuestionIndex < currentQuestionIndex ||
            this.isValidAndAllowedToMoveFromQuestion()
        )
            this.setState(
                {
                    ...this.state,
                    ...COMMON_NEXT_OR_PREVIOUS_QUESTION_STATE_VARIABLES,
                    currentQuestionIndex: destinationQuestionIndex,
                },
                () => {
                    this.fetchAndSetCurrentQuestionDetails();
                },
            );
    };

    /**
     * Handles the fact that the user has selected a given option.
     * Basically handles the toggle operation and other stuff.
     */
    handleOptionClicked = (optionId) => {
        const { currentSelectedOptions } = this.state;

        if (!currentSelectedOptions.includes(optionId)) {
            currentSelectedOptions.push(optionId);
        } else {
            currentSelectedOptions.pop(optionId);
        }

        this.setState({
            ...this.state,
            currentSelectedOptions,
        });

        this.IsNextArrowValid();
    };

    /**
     * Handles the fact that the user has clicked on the previous question button.
     * Makes necessary api calls and stuff.
     */
    handlePreviousQuestionButtonClicked = () => {
        let { currentQuestionIndex } = this.state;
        this.setState(
            {
                ...this.state,
                ...COMMON_NEXT_OR_PREVIOUS_QUESTION_STATE_VARIABLES,
                currentQuestionIndex: (currentQuestionIndex -= 1),
            },
            () => {
                this.fetchAndSetCurrentQuestionDetails();
            },
        );
    };

    /**
     * Handles the fact that the user has clicked on the next question button.
     * Makes necessary api calls and stuff.
     */
    handleNextQuestionButtonClicked = () => {
        let { currentQuestionIndex } = this.state;

        if (this.isValidAndAllowedToMoveFromQuestion())
            this.setState(
                {
                    ...this.state,
                    ...COMMON_NEXT_OR_PREVIOUS_QUESTION_STATE_VARIABLES,
                    currentQuestionIndex: (currentQuestionIndex += 1),
                },
                () => {
                    this.fetchAndSetCurrentQuestionDetails();
                },
            );
    };

    /**
     * Handles the fact that the user wants to save the progress of the current
     * step. Validates the user input and send request to server etc.
     */
    handleSaveAndNextQuestionClicked = () => {
        if (this.isValidAndAllowedToMoveFromQuestion()) {
            const { currentSelectedOptions } = this.state;

            if (currentSelectedOptions.length > 0) {
                const { setPercentageCompleted } = this.props;

                const {
                    globalQuestionAndOptionsSelected,
                    answersDict,
                    allAvailableQuestionsStore,
                } = this.state;
                const { currentQuestionData } = this.state.serverData; // from server

                const questionData = this.getCurrentQuestionDetails(); // in-app

                // pre-process
                globalQuestionAndOptionsSelected[questionData.id] = [...currentSelectedOptions];

                answersDict[questionData.id] = [];
                currentQuestionData.options.map((option) => {
                    if (currentSelectedOptions.includes(option.id))
                        answersDict[questionData.id].push(option.identity);
                });

                // save
                setPercentageCompleted(
                    (Object.keys(globalQuestionAndOptionsSelected).length /
                        allAvailableQuestionsStore.length) *
                        100,
                );
                this.setState(
                    {
                        ...this.state,
                        globalQuestionAndOptionsSelected,
                        answersDict,
                    },
                    () => {
                        const { currentQuestionIndex } = this.state;
                        const hasNextQuestion =
                            currentQuestionIndex < allAvailableQuestionsStore.length - 1;
                        if (hasNextQuestion) {
                            this.handleNextQuestionButtonClicked();
                        }
                    },
                );
            } else this.handleSkipThisQuestionClicked(); // chain of responsibility
        }
    };

    /**
     * Handles the fact that the user has clicked the skip this question
     * button, very similar to `handleSkipThisQuestionClicked`.
     */
    handleSkipThisQuestionClicked = () => {
        const { globalQuestionAndOptionsSelected, answersDict } = this.state;

        const questionData = this.getCurrentQuestionDetails();

        // pre-process | reset the selected stuff for the question
        delete globalQuestionAndOptionsSelected[questionData.id];
        delete answersDict[questionData.id];

        this.setState(
            {
                ...this.state,
                globalQuestionAndOptionsSelected,
                answersDict,
            },
            () => this.handleNextQuestionButtonClicked(),
        );
    };

    /**
     * Returns if the current question is required or not. This is parent
     * deciding function to check if the question can be skipped or not.
     */
    checkIfCurrentQuestionRequired = () => this.getCurrentQuestionGroupDetails().is_required;

    /**
     * Checks if the user is allowed to move to the next question, returns a boolean accordingly.
     * If not allowed, then sets the error message, why so. This is the validation function.
     */
    isValidAndAllowedToMoveFromQuestion = () => {
        const questionGroupData = this.getCurrentQuestionGroupDetails();
        const { currentSelectedOptions } = this.state;

        if (questionGroupData.is_required === true && currentSelectedOptions.length === 0) {
            this.setState({
                ...this.state,
                errorInformation: `
                    Please select at least one option to continue to the
                    next question.
                    `,
            });
            return false;
        }

        return true;
    };

    /**
     * User has filled all the necessary questions and wishes to view
     * the predicted models for his inputs. Make the server call
     * and move to the next page.
     */
    handleViewPredictedModelsButtonClicked = () => {
        this.setState({
            ...this.state,
            isLoading: true,
        });

        triggerSimpleAjax(`api/generate-recommended-model/`, 'post', {
            other_linkages: this.getAllSelectedOptionIds(),
        }).then((responseData) => {
            this.setState(
                {
                    ...this.state,
                    isLoading: false,
                },
                () => {
                    const { setViewerToken } = this.props;
                    setViewerToken(responseData.token);
                    this.changeWindowLocation(APP_DATA_MODELS_RECOMMENDATION_PAGE_URL);
                },
            );
        });
    };

    /**
     * Called when a option is clicked
     * Checks if all the options that are currently saved in answerDict
     * If not updates the state to disable the next Arrow
     * */
    IsNextArrowValid = () => {
        const {
            allAvailableQuestionsStore,
            currentQuestionIndex,
            answersDict,
            currentSelectedOptions,
        } = this.state;

        const currentQuestionId = allAvailableQuestionsStore[currentQuestionIndex]?.id;
        const currentSavedAnswers = answersDict[currentQuestionId];

        if (currentSavedAnswers) {
            const isNextButtonAllowed =
                currentSavedAnswers.length === currentSelectedOptions.length;
            this.setState({
                allOptionsSaved: !isNextButtonAllowed,
            });
        } else {
            this.setState({
                allOptionsSaved: true,
            });
        }
    };

    /**
     * Called before the return inside render(), this is used as a per layer to
     * get a config dict which contains necessary variables for the jsx.
     * Just a dry layer above the render() layer.
     */
    preRenderAndGetNecessaryConfig = () => {
        const { percentageCompleted } = this.props;

        const {
            currentSelectedOptions,
            isLoading,
            currentQuestionIndex,
            allQuestionGroupIdsAndQuestionIdsStore,
            errorInformation,
            answersDict,
            allAvailableQuestionsStore,
        } = this.state;

        const { questions_groups_and_questions = [], currentQuestionData = {} } =
            this.state.serverData || {}; // server data state

        // derived stuff
        const isCurrentQuestionRequired = this.checkIfCurrentQuestionRequired();

        // pre-processing left side tab data
        const questionGroupsAndQuestionsData = [];
        questions_groups_and_questions.map((questionGroupData) => {
            // questions
            const questionsList = [];
            questionGroupData.questions.map((questionData) => {
                questionsList.push({
                    id: questionData.id,
                    title: questionData.identity,
                    hint: questionData.hint,
                    onClick: () => {
                        this.onQuestionSectionClicked(questionData.id);
                    },
                    isActive: currentQuestionData.id === questionData.id,
                });
            });

            // question groups
            questionGroupsAndQuestionsData.push({
                id: questionGroupData.id,
                title: questionGroupData.identity,
                isRequired: questionGroupData.is_required,
                isCollapsed: !(
                    allQuestionGroupIdsAndQuestionIdsStore[questionGroupData.id] || []
                ).includes(currentQuestionData.id),
                questionsList,
                answersDict,
            });
        });

        // pre-processing current question options
        const currentQuestionOptions = (currentQuestionData.options || []).map((option) => ({
            id: option.id,
            title: option.identity,
            description: option.description,
            info: option.why_we_ask_this,
            infoTitle: option.why_we_ask_this_title,
            isDisabled: option.is_disabled,
            icon: option.image_placeholder,
        }));

        // layout navigation stuff
        const hasPreviousQuestion = currentQuestionIndex !== 0;
        const hasNextQuestion = currentQuestionIndex < allAvailableQuestionsStore.length - 1;

        // progress bar Stuff
        const totalNumberOfAnsweredQuestions = Object.keys(answersDict).length;
        const totalNumberOfQuestions = allAvailableQuestionsStore.length;

        return {
            // layout
            isLoading,
            percentageCompleted,
            // data
            questionGroupsAndQuestionsData,
            currentQuestionIndex,
            currentQuestionData,
            // validations
            errorInformation,
            currentQuestionOptions,
            currentSelectedOptions,
            isCurrentQuestionRequired,
            // navigation
            hasNextQuestion,
            hasPreviousQuestion,
            // progressbar
            totalNumberOfAnsweredQuestions,
            totalNumberOfQuestions,
            // next button
            // isNextButtonAllowed,
        };
    };

    render() {
        const {
            // layout
            isLoading,
            percentageCompleted,
            // data
            questionGroupsAndQuestionsData,
            currentQuestionIndex,
            currentQuestionData,
            // validations
            errorInformation,
            currentQuestionOptions,
            currentSelectedOptions,
            isCurrentQuestionRequired,
            // navigation
            hasNextQuestion,
            hasPreviousQuestion,
            // progressbar
            totalNumberOfAnsweredQuestions,
            totalNumberOfQuestions,
        } = this.preRenderAndGetNecessaryConfig();

        const { allOptionsSaved } = this.state;

        const nextArrowErrorDescription = {
            description: 'Kindly use Save And Next to go to next question',
        };

        return (
            <AppLoadableWrapper isLoading={isLoading}>
                <div className="wizard_based_questions_page">
                    <div className="page_section_1 pt-0">
                        <WizardProgressTracker
                            otherClassNames=""
                            totalAnswered={totalNumberOfAnsweredQuestions}
                            totalQuestions={totalNumberOfQuestions}
                            progress={percentageCompleted}
                        />
                        <div className="row">
                            <div className="col-lg-4 pt-4">
                                <WizardQuestionsTracker data={questionGroupsAndQuestionsData} />
                            </div>
                            <div className="col-lg-8 pt-4">
                                <AppText2
                                    otherClassNames="mb-2"
                                    text={`Question ${currentQuestionIndex + 1}`}
                                    color="primary"
                                    isBold
                                />
                                <AppTitle3
                                    isMultiLine
                                    otherClassNames="mb-2"
                                    text={currentQuestionData.title_1}
                                />
                                <div className="d-flex align-items-baseline mb-2">
                                    <AppTitle3
                                        otherClassNames="mb-0 me-2"
                                        text={currentQuestionData.title_2}
                                        isBold={false}
                                        isMultiLine
                                    />
                                    {currentQuestionData.why_we_ask_this && (
                                        <>
                                            <AppText2
                                                otherClassNames="d-inline ms-1 mb-0 link_underline"
                                                color="primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#question_why_we_ask_this_modal"
                                                text={
                                                    currentQuestionData.why_we_ask_this_title ||
                                                    'Know why we ask this?'
                                                }
                                            />
                                            <InformationModal
                                                title={currentQuestionData.why_we_ask_this_title}
                                                description={currentQuestionData.why_we_ask_this}
                                                modalId="question_why_we_ask_this_modal"
                                            />
                                        </>
                                    )}
                                </div>

                                <div className="d-inline ">
                                    <AppText2
                                        otherClassNames="d-inline "
                                        text="Please select one option from the choices
                                        listed below."
                                    />
                                </div>

                                {errorInformation && (
                                    <div className="alert alert-danger error_alert_1" role="alert">
                                        <AppText1 text={errorInformation} />
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className="page_top_actions justify-content-end">
                                        {isCurrentQuestionRequired || (
                                            <>
                                                <AppJustClickableWrapper
                                                    onClick={this.handleSkipThisQuestionClicked}
                                                >
                                                    <AppText2
                                                        otherClassNames="m-0"
                                                        text="Skip this?"
                                                        color="primary"
                                                        isBold
                                                    />
                                                </AppJustClickableWrapper>
                                                <AppText2
                                                    otherClassNames="m-0 ms-2 me-3"
                                                    text="OR"
                                                />
                                            </>
                                        )}

                                        <InformationModal
                                            title="Successfully Completed"
                                            description="Thank you for your responses! You have
                                            reached the end of our tool, click on the button
                                            below to view related data stewardship models
                                            and case studies."
                                            modalId="viewPredictedModals"
                                            button={
                                                <AppButton
                                                    onClick={() =>
                                                        // eslint-disable-next-line max-len
                                                        this.handleViewPredictedModelsButtonClicked()
                                                    }
                                                    text="View stewardship Models"
                                                    isTextOnly
                                                    color="outline-primary"
                                                    iconOrientation="right"
                                                    data-bs-dismiss="modal"
                                                    icon={
                                                        <AppFontAwesomeIcon
                                                            icon={faChevronRight}
                                                            color="primary"
                                                            size="sm"
                                                            otherClassNames="ms-2"
                                                        />
                                                    }
                                                />
                                            }
                                        />

                                        <AppButton
                                            onClick={this.handleSaveAndNextQuestionClicked}
                                            text="Save & Next"
                                            iconOrientation="right"
                                            icon={
                                                <AppFontAwesomeIcon
                                                    icon={faChevronRight}
                                                    color="white"
                                                    size="sm"
                                                    otherClassNames="ms-2"
                                                />
                                            }
                                            isDisabled={!currentSelectedOptions.length}
                                            data-bs-toggle={!hasNextQuestion && 'modal'}
                                            data-bs-target="#viewPredictedModals"
                                        />
                                    </div>
                                </div>
                                <div className="pb-2 row app_just_row_padded_columns">
                                    {currentQuestionOptions.map((option) => (
                                        <div className="col-md-6" key={`option-${option.id}`}>
                                            <WizardOptionCard
                                                isSelected={currentSelectedOptions.includes(
                                                    option.id,
                                                )}
                                                onClick={() => {
                                                    if (!option.isDisabled)
                                                        this.handleOptionClicked(option.id);
                                                }}
                                                {...option}
                                            />
                                        </div>
                                    ))}

                                    <div className="page_bottom_actions pt-4">
                                        <div className="right_scroll_actions">
                                            <AppButton
                                                onClick={this.handlePreviousQuestionButtonClicked}
                                                otherClassNames="px-2"
                                                color="light"
                                                isDisabled={!hasPreviousQuestion}
                                                icon={
                                                    <AppFontAwesomeIcon
                                                        otherClassNames="text-black"
                                                        icon={faChevronUp}
                                                        size="sm"
                                                    />
                                                }
                                            />
                                            <AppToolTip
                                                content={nextArrowErrorDescription}
                                                show={allOptionsSaved}
                                            >
                                                <div>
                                                    <AppButton
                                                        onClick={
                                                            this.handleNextQuestionButtonClicked
                                                        }
                                                        otherClassNames="px-2 ms-1"
                                                        color="light"
                                                        isDisabled={allOptionsSaved}
                                                        icon={
                                                            <AppFontAwesomeIcon
                                                                otherClassNames="text-black"
                                                                icon={faChevronDown}
                                                                size="sm"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </AppToolTip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLoadableWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    /**
     * Used to set the percentage of the wizard completed by the user.
     * This is used to sync the progress tracker and the header.
     */
    setPercentageCompleted: (percentage) =>
        dispatch({ type: 'SET_PERCENTAGE_COMPLETED', payload: percentage }),
    setViewerToken: (viewer_token) => dispatch({ type: 'SET_VIEWER_TOKEN', payload: viewer_token }),
});

const mapStateToProps = (state) => ({ percentageCompleted: state.percentageCompleted });

export default connect(mapStateToProps, mapDispatchToProps)(WizardBasedQuestionsPage);
