import { AppText1 } from 'components/typography';
import React, { Component } from 'react';

export default class AppButton extends Component {
    render() {
        const {
            color = 'primary',
            otherClassNames = '',
            text = null,
            isTextOnly = false,
            icon = null,
            size = 'normal',
            onClick = () => {},
            iconOrientation = 'left',
            isDisabled = false,
            reference,
            ...otherAttributes
        } = this.props;

        let { isBold = false } = this.props;

        if (['light'].includes(color)) {
            isBold = true;
        }

        return (
            <button
                disabled={isDisabled}
                type="button"
                className={`
                    ${otherClassNames}
                    app_button_1
                    btn btn-${color} btn-size-${size}
                    ${isTextOnly ? 'text_only_button_1' : ''}
                    ${icon ? 'icon_button' : ''}
                `}
                onClick={onClick}
                ref={reference}
                {...otherAttributes}
            >
                {iconOrientation === 'left' && icon}
                {text && (
                    <AppText1 text={text} otherClassNames="m-0" isBold={isBold || isTextOnly} />
                )}
                {iconOrientation === 'right' && icon}
            </button>
        );
    }
}
