import {
    APP_WIZARD_BASED_QUESTIONS_PAGE_URL,
    APP_DATA_MODELS_DETAIL_PAGE_URL,
} from 'common/configurations';
import { SingleDataModelCard, AppButton, AppFontAwesomeIcon, FeedBackCard } from 'components';
import { triggerSimpleAjax } from 'appApi/helper';
import { getAppApiHostUrl } from 'appConfig';
import AppPageComponent from 'common/overrides/AppPageComponent';
import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { AppTitle1 } from 'components/typography';

class DataModelsRecommendationPage extends AppPageComponent {
    constructor(props) {
        super(props);
        this.state = {
            serverData: {},
        };
    }

    componentDidMount() {
        const { viewerToken } = this.props;

        if ([null, NaN, undefined].includes(viewerToken)) {
            this.changeWindowLocation(APP_WIZARD_BASED_QUESTIONS_PAGE_URL);
        } else {
            triggerSimpleAjax(`api/view-recommended-model/${viewerToken}/`).then((responseData) => {
                this.setState({
                    ...this.state,
                    serverData: responseData,
                });
            });
        }
    }

    handleDownloadOptionsClicked = () => {
        const { viewerToken } = this.props;
        const BASE_URL = getAppApiHostUrl();
        window.open(`${BASE_URL}recommendations/${viewerToken}/download/`, '_blank');
    };

    render() {
        const { destination_linkages = [] } = this.state.serverData;
        const SURVEY_LINK =
            // eslint-disable-next-line max-len
            'https://docs.google.com/forms/d/e/1FAIpQLSeV8Y65zc_UJT3zHevW0syz3UcwYj5QYMAUeDGzMjNve9SJtw/viewform?usp=sf_link';

        return (
            <div>
                <div className="page_section_1">
                    <div className="d-flex justify-content-between">
                        <AppTitle1
                            text="These models are aligned with some of the choices
                            you selected"
                            color="primary"
                        />

                        <AppButton
                            text="Download a copy of your responses"
                            icon={
                                <AppFontAwesomeIcon
                                    icon={faDownload}
                                    size="sm"
                                    color="white"
                                    otherClassNames="me-3"
                                />
                            }
                            onClick={this.handleDownloadOptionsClicked}
                            otherClassNames="ms-5"
                        />
                    </div>

                    <div className="row app_just_row_padded_columns">
                        {destination_linkages.map((element) => (
                            <div className="col-md-6 col-lg-4" key={element.id + element.identity}>
                                <SingleDataModelCard
                                    title={element.identity}
                                    description={element.description}
                                    keyCharacteristicsList={element.key_characteristics}
                                    caseStudyImagesList={element.case_study_logo_images}
                                    viewInDetailHandler={() => {
                                        this.changeWindowLocation(APP_DATA_MODELS_DETAIL_PAGE_URL, {
                                            urlParams: { id: element.id },
                                        });
                                    }}
                                />
                            </div>
                        ))}

                        <div className="col-md-6 col-lg-4">
                            <FeedBackCard
                                title="What did you think of this tool?"
                                description="We’d love to hear about
                                your experience using this Stewardship Navigator.
                                Please fill out our feedback
                                form to help us continue to develop this tool."
                                link={SURVEY_LINK}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ viewerToken: state.viewerToken });

export default connect(mapStateToProps)(DataModelsRecommendationPage);
