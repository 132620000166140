import AppPageComponent from 'common/overrides/AppPageComponent';
import { SingleDataModelCard, AppTitleAndSubTitle } from 'components';
import { APP_DATA_MODELS_DETAIL_PAGE_URL } from 'common/configurations';
import React from 'react';

export default class OurDataModelsPage extends AppPageComponent {
    API_ENDPOINT_FOR_INITIAL_PAGE_DATA = 'api/our-data-models-page-data/';

    render() {
        const { our_data_models = [] } = this.state.serverData;

        return (
            <div>
                <div className="page_section_1">
                    <AppTitleAndSubTitle
                        title="Data Stewardship models"
                        subTitle="No one size-fits-all exists for data governance & stewardship.
                        Models and mechanisms will vary based on sector, use-case, purpose and
                        regulation. A robust data economy requires the existence of various models
                         or intermediary types a few of which have been presented below"
                        titleSize="large"
                        otherClassNames="max-width-70"
                    />
                    <div className="row app_just_row_padded_columns">
                        {our_data_models.map((element) => (
                            <div className="col-md-6 col-lg-4" key={element.id + element.identity}>
                                <SingleDataModelCard
                                    id={element.id}
                                    title={element.identity}
                                    description={element.description}
                                    keyCharacteristicsList={element.key_characteristics}
                                    caseStudyImagesList={element.case_study_logo_images}
                                    viewInDetailHandler={() => {
                                        this.changeWindowLocation(APP_DATA_MODELS_DETAIL_PAGE_URL, {
                                            urlParams: { id: element.id },
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
