import React, { Component } from 'react';

/**
 * The loading wrapper for any children or page. Used to denote that an api call has
 * been made and children are loading. The isLoading is got from the page's state.
 */
export default class AppLoadableWrapper extends Component {
    render() {
        const { children, isLoading } = this.props;

        if (isLoading) {
            return (
                <div className="app_loadable_wrapper_1">
                    {/* the loading based stuff */}
                    <div className="overlay" />

                    <div className="app_loader_1">
                        <div className="spinner-grow text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    {/* children */}
                    {children}
                </div>
            );
        }

        return children;
    }
}
