import { AppLink, AppButton } from 'components';
import { DecisionMakingCardImage1 } from 'assets/images';
import React, { Component } from 'react';

import { AppTitle1, AppText1 } from './typography';

export default class DecisionMakingCard extends Component {
    render() {
        const {
            // subHeading,
            heading,
            description,
            buttonContent = [],
            isReversed,
            image,
            otherClassNames = '',
        } = this.props;

        return (
            <div
                className={`app_decision_making_card_wrapper ${
                    isReversed ? 'revered' : 'not_revered'
                } ${otherClassNames}`}
            >
                <div className="app_decision_making_card card">
                    <div className="app_decision_making_card_body card-body">
                        <div className="app_decision_content_block">
                            {/* <AppTitle4 text="Steps of Steward Setup" /> */}
                            <AppTitle1 text={heading} color="primary" />
                            <AppText1 text={description} isMultiLine />
                            {buttonContent.map(({ link, text, externalLink }) => (
                                <AppLink key={link} href={externalLink} to={link}>
                                    <AppButton text={text} otherClassNames="me-3" />
                                </AppLink>
                            ))}
                        </div>

                        <div className="app_decision_image_block">
                            <img
                                src={image || DecisionMakingCardImage1}
                                alt=""
                                className="app_decision_image app_image_icon_1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
