import { getAppApiHostUrl } from 'appConfig';
import axios from 'axios';

/**
 * The api host for the entire app. Given as a base url
 * for the axios library.
 */
export const BASEURL = getAppApiHostUrl();

/**
 * The centralized function for triggering api calls to the host. This
 * inturn returns a promise which is got and processed on caller.
 */
export const triggerSimpleAjax = (
    endpoint,
    method = 'get',
    data = {},
    params = {},
    headers = {
        'Content-Type': 'application/json',
    },
) => {
    // append auth header only if it is present
    if (localStorage.getItem('auth_key', null)) {
        headers.Authorization = `Token ${localStorage.getItem('auth_key', null)}`;
    }

    return new Promise((resolve, reject) => {
        axios({
            method,
            url: endpoint,
            baseURL: BASEURL,
            headers,
            params,
            data,
            withCredentials: true,
        })
            .then((response) => {
                if ([200, 201, 204].includes(response.status)) {
                    // successful response
                    resolve(response.data);
                } else {
                    // unhandled
                    reject(response);
                }

                // log data from server
                // eslint-disable-next-line no-console
                console.log(response, response.data);
            })
            .catch((error) => {
                const { response } = error;

                if ([401, 403].includes(response.status)) {
                    // user is unauthenticated
                    if (localStorage.getItem('auth_key')) {
                        localStorage.removeItem('auth_key');
                        window.location.href = '/login';
                    }
                } else if ([400, 406].includes(response.status)) {
                    // bad request is sent to server
                    reject(response.data);
                } else {
                    // unhandled
                    // eslint-disable-next-line no-alert
                    alert('Unhandled Error Response');
                    reject(error);
                }

                // log data from server
                // eslint-disable-next-line no-console
                console.log(response, error);
            });
    });
};
