import { AppButton } from 'components';
import React from 'react';

import { AppTitle4, AppText1 } from './typography';

export default function InformationModal({
    title,
    modalId = 'modal-id',
    description,
    buttonHide = false,
    button = '',
}) {
    return (
        <div
            className="modal information_modal_1 fade"
            id={modalId}
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center py-4">
                        <AppTitle4 text={title || 'Why we ask this?'} otherClassNames="mt-2" />
                        <AppText1 text={description} otherClassNames="mb-0" />
                    </div>
                    {buttonHide ? null : (
                        <div className="modal-footer py-0 px-3">
                            {button || (
                                <AppButton
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    text="Ok, got it"
                                    isTextOnly
                                    color="outline-primary"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
