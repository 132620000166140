/**
 * Returns the api host url for the application, based on the environment set.
 * In some cases the server will be running on local itself.
 */
export const getAppApiHostUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8000/'; // server is running in local machine
    }

    return 'https://tool.thedataeconomylab.com/';
};
